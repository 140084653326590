var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DataTable',{attrs:{"headers":_vm.headers,"model":_vm.data,"pagination":false},on:{"updateData":_vm.fetchData},scopedSlots:_vm._u([{key:"item.isChecked",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-center align-content-center",on:{"click":function($event){$event.stopPropagation();}}},[_c('el-checkbox',{staticClass:"m-0",attrs:{"disabled":!!row.loading,"value":!!row.checked_at},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onChecked.apply(void 0, [ row ].concat( argsArray ))}}})],1)]}},{key:"item.content",fn:function(ref){
var row = ref.row;
return [(row.isEditMode)?_c('div',[_c('el-input',{staticClass:"w-100",attrs:{"disabled":!!row.loading,"size":_vm.size,"autosize":"","type":"textarea"},model:{value:(row.editedContent),callback:function ($$v) {_vm.$set(row, "editedContent", $$v)},expression:"row.editedContent"}})],1):_c('div',{staticClass:"mx-2 my-1",staticStyle:{"white-space":"normal","overflow-wrap":"break-word"}},[(!row.checked_at)?_c('span',[_vm._v(" "+_vm._s(row.content)+" ")]):_c('s',[_vm._v(" "+_vm._s(row.content)+" ")])])]}},{key:"item.options",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex mx-1 justify-space-around row__options",class:{'row__options_disabled': row.loading}},[(row.isEditMode)?[_c('div',{staticClass:"options__button text-success",on:{"click":function($event){$event.stopPropagation();return _vm.updateIssueContent(row)}}},[_c('font-awesome-icon',{attrs:{"icon":"check-circle"}})],1),(row.isEditMode)?_c('div',{staticClass:"options__button text-danger",on:{"click":function($event){$event.stopPropagation();return _vm.resetIssueContent(row)}}},[_c('font-awesome-icon',{attrs:{"icon":"times"}})],1):_vm._e()]:[(_vm.ifHasPermission(['wo-task-edit']))?_c('div',{staticClass:"options__button",on:{"click":function($event){$event.stopPropagation();return _vm.enableEditMode(row)}}},[_c('font-awesome-icon',{attrs:{"icon":"edit"}})],1):_vm._e(),(_vm.ifHasPermission(['wo-task-delete']))?_c('div',{staticClass:"options__button text-danger",on:{"click":function($event){$event.stopPropagation();return _vm.remove(row)}}},[_c('font-awesome-icon',{attrs:{"icon":"trash"}})],1):_vm._e()]],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }