<template>
  <div>
    <CreateForm
        :title="title"
        :workOrderId="workOrderId"
        @created="onNewIssueCreated"
        v-if="ifHasPermission(['wo-task-create'])"
    />
    <IssuesList
        :data="issues"
        @fetchData="fetchData"
    />
  </div>
</template>

<script>
import axios from 'axios';
import IssuesList from '@/components/WorkOrderComponents/Issues/IssuesList';
import notification from '@/notification/notify';

export default {
  name: 'WorkOrderIssues',
  components: {
    IssuesList,
    CreateForm: () => import('@/components/WorkOrderComponents/Issues/CreateForm'),
  },
  props: {
    title: {
      type: String,
    },
    workOrderId: {
      type: [Number, String],
      required: true,
    },
  },
  data: () => ({
    issues: [],
  }),
  methods: {
    async fetchData() {
      try {
        const {data} = await axios.get(`workorders/order/${this.workOrderId}/tasks`);
        this.issues = data.reverse();
      } catch (error) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_load_issues'),
            'error');
        console.log('error', error);
      }
    },
    onNewIssueCreated() {
      this.fetchData();
    },
  },
};
</script>

<style scoped>

</style>
