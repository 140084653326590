<template>
  <div>
    <DataTable
        :headers="headers"
        :model="data"
        :pagination="false"
        @updateData="fetchData"
    >
      <template v-slot:item.isChecked="{row}">
        <div
            @click.stop=""
            class="d-flex justify-center align-content-center"
        >
          <el-checkbox
              :disabled="!!row.loading"
              :value="!!row.checked_at"
              @change="onChecked(row, ...arguments)"
              class="m-0"
          />
        </div>
      </template>
      <template v-slot:item.content="{row}">
        <div v-if="row.isEditMode">
          <el-input
              :disabled="!!row.loading"
              :size="size"
              autosize
              class="w-100"
              type="textarea"
              v-model="row.editedContent"
          />
        </div>
        <div
            class="mx-2 my-1"
            style="white-space: normal; overflow-wrap: break-word;"
            v-else
        >
          <span v-if="!row.checked_at">
            {{ row.content }}
          </span>
          <s v-else>
            {{ row.content }}
          </s>
        </div>
      </template>
      <template v-slot:item.options="{row}">
        <div
            :class="{'row__options_disabled': row.loading}"
            class="d-flex mx-1 justify-space-around row__options"
        >
          <template v-if="row.isEditMode">
            <div
                @click.stop="updateIssueContent(row)"
                class="options__button text-success"
            >
              <font-awesome-icon icon="check-circle" />
            </div>
            <div
                @click.stop="resetIssueContent(row)"
                class="options__button text-danger"
                v-if="row.isEditMode"
            >
              <font-awesome-icon icon="times" />
            </div>
          </template>
          <template v-else>
            <div
                @click.stop="enableEditMode(row)"
                class="options__button"
                v-if="ifHasPermission(['wo-task-edit'])"
            >
              <font-awesome-icon icon="edit" />
            </div>
            <div
                @click.stop="remove(row)"
                class="options__button text-danger"
                v-if="ifHasPermission(['wo-task-delete'])"
            >
              <font-awesome-icon icon="trash" />
            </div>
          </template>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import Vue from 'vue';
import DataTable from '@/components/Table/DataTable';
import axios from 'axios';
import notification from '@/notification/notify';
import swal from 'sweetalert2';

export default {
  name: 'WorkOrderIssuesList',
  components: {DataTable},
  props: {
    data: {
      type: Array,
    },
  },
  data: () => ({
    headers: [
      {
        key: 'isChecked',
        label: '',
        sort: false,
        width: '20px',
      },
      {
        key: 'content',
        label: 'system.content',
        width: '250px',
        sort: false,
      },
      {
        key: 'options',
        label: 'system.options',
        width: '10px',
        sort: false,
      },
    ],
    size: 'mini',
  }),
  created() {
    this.fetchData();
  },
  methods: {
    async updateIssueContent(issue) {
      const candidateIdx = this.data.findIndex(item => item.id === issue.id);
      if (candidateIdx >= 0) {
        Vue.set(this.data[candidateIdx], 'loading', true);
        issue.content = issue.editedContent;
        try {
          const {data} = await this.updateIssue(issue);
          this.data.splice(candidateIdx, 1, data);
        } catch (error) {
          console.log('updateError', error);
          Vue.set(this.data[candidateIdx], 'loading', true);
          notification.notify(
              this.$t('notify.error'),
              this.$t('system.can_not_update'),
              'error');
        }
      }
    },
    resetIssueContent(issue) {
      const candidateIdx = this.data.findIndex(item => item.id === issue.id);
      if (candidateIdx >= 0) {
        Vue.set(this.data[candidateIdx], 'isEditMode', false);
      }
    },
    async remove(issue) {
      const result = await swal.fire({
        target: this.$refs.root,
        title: this.$t('system.remove'),
        text: this.$t('system.if_you_sure'),
        type: 'info',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: this.$t('system.yes'),
        cancelButtonText: this.$t('system.no'),
      });
      if (result.value) {
        const candidateIdx = this.data.findIndex(item => item.id === issue.id);
        if (candidateIdx >= 0) {
          Vue.set(this.data[candidateIdx], 'loading', true);
          try {
            await axios.delete(`workorders/task/${issue.id}`);
            this.fetchData();
          } catch (error) {
            notification.notify(
                this.$t('notify.error'),
                this.$t('system.can_not_remove'),
                'error');
            console.log('removeError', error);
          } finally {
            Vue.set(this.data[candidateIdx], 'loading', false);
          }
        }
      }
    },
    enableEditMode(issue) {
      const candidateIdx = this.data.findIndex(item => item.id === issue.id);
      if (candidateIdx >= 0) {
        Vue.set(this.data[candidateIdx], 'editedContent', issue.content);
        Vue.set(this.data[candidateIdx], 'isEditMode', true);
      }
    },
    async onChecked(issue) {
      const candidateIdx = this.data.findIndex(item => item.id === issue.id);
      if (candidateIdx >= 0) {
        Vue.set(this.data[candidateIdx], 'loading', true);

        try {
          const {data} = await this.updateIssue({...issue, checked_at: !issue.checked_at});
          const oldRowIdx = this.data.findIndex(row => row.id === data.id);
          if (oldRowIdx >= 0) {
            this.data.splice(oldRowIdx, 1, data);
          }
        } catch (error) {
          console.log('error', error);
          notification.notify(
              this.$t('notify.error'),
              this.$t('system.can_not_update'),
              'error');
        } finally {
          Vue.set(this.data[candidateIdx], 'loading', false);
        }
      }
    },

    async updateIssue(issue) {
      return await axios.post(`workorders/task/${issue.id}`, {...issue, checked_at: !!issue.checked_at});
    },
    fetchData() {
      this.$emit('fetchData');
    },
  },
};
</script>

<style
    lang="scss"
    scoped
>
.row__options {
  &_disabled {
    pointer-events: none;
    opacity: .4;
  }

  .options__button {
    padding: 5px 7px;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background: #d0d0d0;
    }

    &:active {
      background: #c8c8c8;
    }
  }
}
</style>
